import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout/Layout';
import { graphql, Link } from 'gatsby';
import { PortfolioProvider } from '../context/context';
import { Fade } from "react-awesome-reveal";
import { headerData, footerData } from '../mock/data';
import BlogTitle from '../components/BlogTitle/BlogTitle';
import { SEO } from "../components/SEO/SEO";

function Blog( { data } ) {
  const [header, setHeader] = useState({});
  const [footer, setFooter] = useState({});
  const [postCount, setCount] = useState('');

const getPostCount = () => {
  if (data.allMarkdownRemark.totalCount > 1) {
    return "Posts";
  } else {
    return "Post";
  }
};

  useEffect(() => {
    setHeader({ ...headerData });
    setFooter({ ...footerData });
    setCount(getPostCount)
  }, []);

  return (
    <PortfolioProvider value={{ header, footer }}>
      <Layout>
      <SEO title={'Nick Jeske - Blog | Software Engineer & Blogger'}/>
      <div className="blog">
        <BlogTitle title="Nick's Thoughts"/>
        <Fade triggerOnce duration={1000} delay={1200}>
        <h3>{data.allMarkdownRemark.totalCount} {postCount}</h3>
        <div className='grid'>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <Link className='blogLink' key={node.id} to={node.fields.slug}>
            <div className='blogBody'>
                <h3>
                  {node.frontmatter.title} <span>{node.frontmatter.date}</span>
                </h3>
                <p>{node.frontmatter.description || node.excerpt}</p>
                {node.frontmatter.tags && (
                  <p className='tags'>Tags: <span>{node.frontmatter.tags}</span></p>
                )}
            </div>
          </Link>
        ))}
        </div>
      </Fade>
      </div>
      </Layout>
    </PortfolioProvider>
  );
}

export default Blog;

// export const Head = () => (
//   <SEO title={'Nick Jeske - Blog | Software Engineer & Blogger'}/>
// );

export const query = graphql`
query {
  allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD YYYY")
            description
            tags
          }
          fields {
            slug
          }
          excerpt(truncate: true)
        }
      }
    }
  }`
